var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClientStatus column" },
    [
      _c(
        "div",
        { staticClass: "row row--align-center row--justify-between mb-7" },
        [
          _c("label", { staticClass: "fs-16 fw-med" }, [_vm._v("Status")]),
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white",
              attrs: { id: "Testing__AddClientStatusBtn" },
              on: {
                click: function($event) {
                  _vm.showModal = true
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-add-circle" }),
              _vm._v("\n      Add Status\n    ")
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "ClientStatus__list-wrapper column",
          attrs: { id: "Testing__ClientStatusUpdatesList" }
        },
        [
          _vm._m(0),
          _vm._l(_vm.statuses, function(status, index) {
            return _c(
              "div",
              {
                key: "status-" + index,
                staticClass: "ClientStatus__list-item row"
              },
              [
                _c("label", { staticClass: "fs-14" }, [
                  _vm._v(_vm._s(status.created_at))
                ]),
                _c("div", [
                  _c(
                    "span",
                    {
                      class: [
                        "ClientStatus__status-flag fs-12 uppercase",
                        {
                          "ClientStatus__status-flag--active":
                            status.status === "active",
                          "ClientStatus__status-flag--inactive":
                            _vm.inactiveStatuses.indexOf(status.status) !== -1,
                          "ClientStatus__status-flag--pending":
                            status.status === "pending"
                        }
                      ]
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(status.status) + "\n        "
                      )
                    ]
                  )
                ]),
                _c("label", { staticClass: "fc-mid fs-14" }, [
                  _vm._v(_vm._s(status.notes))
                ])
              ]
            )
          })
        ],
        2
      ),
      _vm.showModal
        ? _c("base-modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n      What kind of status would you like to add?\n    "
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "column" }, [
                        _c(
                          "div",
                          { staticClass: "row row--wrap" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "column column--width-auto mr-10"
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "fc-light fs-12 mb-7",
                                    attrs: { for: "status" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              STATUS\n            "
                                    )
                                  ]
                                ),
                                _c("v-select", {
                                  staticClass: "ClientStatus__status-select",
                                  attrs: {
                                    "aria-label": "Status select input",
                                    clearable: false,
                                    id: "status",
                                    options: _vm.statusOptions
                                  },
                                  model: {
                                    value: _vm.statusToAdd,
                                    callback: function($$v) {
                                      _vm.statusToAdd = $$v
                                    },
                                    expression: "statusToAdd"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "transition",
                              { attrs: { name: "Transition__fade" } },
                              [
                                _vm.statusToAdd === "released"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column column--width-auto mb-10"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "fc-light fs-12 mb-7",
                                            attrs: { for: "release" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                RELEASED REASON\n              "
                                            )
                                          ]
                                        ),
                                        _c("v-select", {
                                          staticClass:
                                            "ClientStatus__release-select",
                                          attrs: {
                                            "aria-label":
                                              "Release reason select input",
                                            clearable: false,
                                            id: "release",
                                            options: _vm.releaseOptions
                                          },
                                          model: {
                                            value: _vm.releaseReason,
                                            callback: function($$v) {
                                              _vm.releaseReason = $$v
                                            },
                                            expression: "releaseReason"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "base-input",
                              {
                                staticClass: "mr-10",
                                attrs: {
                                  label: true,
                                  "label-for": "Note",
                                  type: "text"
                                },
                                model: {
                                  value: _vm.note,
                                  callback: function($$v) {
                                    _vm.note =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "note"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            REASON FOR CHANGE\n          "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "row mt-21" },
                          [
                            _c(
                              "base-button",
                              {
                                staticClass: "bg-blue fc-white mr-10",
                                attrs: {
                                  disabled: !_vm.statusToAdd,
                                  id: "Testing__UpdateClientStatusSubmitBtn"
                                },
                                on: { click: _vm.updateClientStatusAPI }
                              },
                              [_vm._v("\n            Submit\n          ")]
                            ),
                            _c(
                              "base-button",
                              {
                                staticClass: "bg-light fc-white",
                                on: {
                                  click: function($event) {
                                    _vm.showModal = false
                                    _vm.statusToAdd = null
                                  }
                                }
                              },
                              [_vm._v("\n            Cancel\n          ")]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2437322193
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ClientStatus__list-header row" }, [
      _c("label", { staticClass: "fc-light fs-12 uppercase" }, [
        _vm._v("Date")
      ]),
      _c("label", { staticClass: "fc-light fs-12 uppercase" }, [
        _vm._v("Status")
      ]),
      _c("label", { staticClass: "fc-light fs-12 uppercase" }, [
        _vm._v("Notes")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }