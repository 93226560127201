<template>
  <div class="ClientStatus column">
    <div class="row row--align-center row--justify-between mb-7">
      <label class="fs-16 fw-med">Status</label>
      <base-button
        @click="showModal = true"
        class="bg-blue fc-white"
        id="Testing__AddClientStatusBtn"
      >
        <i class="fa fa-add-circle" />
        Add Status
      </base-button>
    </div>

    <div
      class="ClientStatus__list-wrapper column"
      id="Testing__ClientStatusUpdatesList"
    >
      <div class="ClientStatus__list-header row">
        <label class="fc-light fs-12 uppercase">Date</label>
        <label class="fc-light fs-12 uppercase">Status</label>
        <label class="fc-light fs-12 uppercase">Notes</label>
      </div>

      <div
        v-for="(status, index) of statuses"
        :key="`status-${index}`"
        class="ClientStatus__list-item row"
      >
        <label class="fs-14">{{ status.created_at }}</label>
        <div>
          <span
            :class="['ClientStatus__status-flag fs-12 uppercase', {
              'ClientStatus__status-flag--active': status.status === 'active',
              'ClientStatus__status-flag--inactive': inactiveStatuses.indexOf(status.status) !== -1,
              'ClientStatus__status-flag--pending': status.status === 'pending',
            }]"
          >
            {{ status.status }}
          </span>
        </div>
        <label class="fc-mid fs-14">{{ status.notes }}</label>
      </div>
    </div>

    <base-modal v-if="showModal">
      <template v-slot:label>
        What kind of status would you like to add?
      </template>

      <template v-slot:actions>
        <div class="column">
          <div class="row row--wrap">
            <div class="column column--width-auto mr-10">
              <label
                class="fc-light fs-12 mb-7"
                for="status"
              >
                STATUS
              </label>
              <v-select
                v-model="statusToAdd"
                aria-label="Status select input"
                class="ClientStatus__status-select"
                :clearable="false"
                id="status"
                :options="statusOptions"
              />
            </div>
            <transition name="Transition__fade">
              <div
                v-if="statusToAdd === 'released'"
                class="column column--width-auto mb-10"
              >
                <label
                  class="fc-light fs-12 mb-7"
                  for="release"
                >
                  RELEASED REASON
                </label>
                <v-select
                  v-model="releaseReason"
                  aria-label="Release reason select input"
                  class="ClientStatus__release-select"
                  :clearable="false"
                  id="release"
                  :options="releaseOptions"
                />
              </div>
            </transition>
            <base-input
              v-model.trim="note"
              class="mr-10"
              :label="true"
              :label-for="'Note'"
              :type="'text'"
            >
              REASON FOR CHANGE
            </base-input>
          </div>
          <div class="row mt-21">
            <base-button
              @click="updateClientStatusAPI"
              class="bg-blue fc-white mr-10"
              :disabled="!statusToAdd"
              id="Testing__UpdateClientStatusSubmitBtn"
            >
              Submit
            </base-button>
            <base-button
              @click="showModal = false; statusToAdd = null"
              class="bg-light fc-white"
            >
              Cancel
            </base-button>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import { Client } from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseModal from '../../../components/base-modal.vue'
import BaseInput from '../../../components/base-input.vue'

export default {
  name: 'ClientStatus',

  components: {
    BaseButton,
    BaseModal,
    BaseInput,
  },

  async created () {
    await this.getClientStatusHistory()
  },

  data () {
    return {
      inactiveStatuses: ['on hold', 'released', 'out of business'],
      note: '',
      releaseOptions: ['switched factoring company', 'no longer factoring'],
      releaseReason: null,
      showModal: false,
      statuses: null,
      statusOptions: ['pending', 'active', 'on hold', 'released', 'out of business'],
      statusToAdd: null,
      user: JSON.parse(localStorage.getItem('user')),
    }
  },

  methods: {
    async getClientStatusHistory () {
      this.progressStart()

      try {
        this.statuses = (await Client.history({
          fields: 'status',
          id: this.$route.params.id,
        })).data.rows.map(change => {
          return {
            created_at: moment(change.updated_at).format('M/D/YY'),
            notes: change.changelog_notes,
            status: change.status,
          }
        })
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Status "Get Status History"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s status history' })
      }
    },

    getReleaseReason() {
      // This covers if a user selects release, selects a reason, then changes the status
      if (this.statusToAdd !== 'released') return 'none'

      if (this.releaseReason === 'switched factoring company') return 'competitor'
      if (this.releaseReason === 'no longer factoring') return 'stopped factoring'

      return 'none'
    },

    async updateClientStatusAPI () {
      this.progressStart()

      try {
        await Client.update({
          changelog_notes: `
            ${this.note ? `${this.note} ` : ''}
            By ${this.user.employee.first_name} ${this.user.employee.last_name}
          `,
          id: this.$route.params.id,
          release_reason: this.getReleaseReason(),
          status: this.statusToAdd,
        })
        this.statuses.unshift({
          created_at: moment().format('M/D/YY'),
          notes: `
            ${this.note ? `${this.note} ` : ''}
            By ${this.user.employee.first_name} ${this.user.employee.last_name}
          `,
          status: this.statusToAdd
        })

        let successMessage = 'The client\'s status has been updated'
        if (this.statusToAdd === 'released') {
          successMessage = `The client has been released succesfully.
          A release letter is being generated and will available in Docs shortly`
        }
        this.note = ''
        this.showModal = false
        this.statusToAdd = null
        this.requestSuccess({ message: successMessage })
      }
      catch (error) {
        if (
          (error.response.data && error.response.data.name === 'ConflictError')
        ) {
          this.progressFail()
          this.alertInfo(error.response.data.message)
          return
        }
        this.captureSentryEvent(
          'Client Status "Update"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the client\'s status' })
      }
    },
  }
}
</script>

<style lang="sass">
.ClientStatus
  padding: rem(30px) rem(35px)
  position: relative

  &__list-header
    label:nth-child(1)
      margin-right: 2rem
      width: rem(100px)
    label:nth-child(2)
      margin-right: 2rem
      width: rem(125px)

    label:nth-child(3)
      flex: 1

  &__list-item
    border-bottom: $border
    padding: rem(20px) 0

    label:nth-child(1)
      margin-right: 2rem
      width: rem(100px)
    div
      margin-right: 2rem
      width: rem(125px)

    label:nth-child(3)
      flex: 1

    &:last-child
      border-bottom: none

  &__release-select
    width: rem(265px)

  &__status-flag
    border: rem(1px) solid transparent
    border-radius: $border-radius
    padding: rem(3px) rem(10px)

    &--active
      background-color: #F4FFF6
      border-color: $success
      color: $success

    &--pending
      background-color: #FFFAF1
      border-color: $alert
      color: $alert

    &--inactive
      background-color: #FBFBFD
      border-color: $borders
      color: $text-dark

  &__status-select
    width: rem(185px)
</style>
